$(document).ready(function() {


    $('#newsletter-button').on('click', handleSubmitClick);
    $('label[for=newsletter-message]').fadeOut();

    function handleSubmitClick(sender) {
        sender.preventDefault();
        var isValid = validateFields();
        if (!isValid) { return }
        handleSubscription();
    }


    function handleSubscription() {

        var email = $('#newsletter-email-field').val();
        var message = "Please Wait";
        showOvelayLoader(message);

        $.ajax({
            url: "https://api.restorationofhearts.org/v1/newsletter",
            type: "POST",
            contentType: 'application/json',
            data: JSON.stringify({ "email" : email }),
            success: function(data, textStatus, jqXHR) {
                $('#newsletter-email-field').val('');
                var successMessage = "Thank you for subscribing"
                updateOverlayMessage(loaderStateSuccess, successMessage);
                setTimeout(function() { closeOverlayLoader(); }, 3000);
            },
            error: function(jqXHR, textStatus, errorThrown) {

                var message = jqXHR.responseJSON["message"]
                if (message) {
                    updateOverlayMessage(loaderStateFailure, message);
                    setTimeout(function() { closeOverlayLoader(); }, 3000);
                } else {
                    var errorMessage = "Your request cannot be processed at this time, please try again later"
                    updateOverlayMessage(loaderStateFailure, errorMessage);
                    setTimeout(function() { closeOverlayLoader(); }, 3000);
                }
            }
        });
    }


    function validateFields() {

        var emailField = $('#newsletter-email-field');
        if (validate({ email: emailField.val() }, emailRule()) !== undefined) {
            var label = $('label[for=newsletter-message]');
            label.text("Please enter a valid email");
            label.fadeIn();
            setTimeout(function() { label.fadeOut(); }, 3000);
            return false
        }

        return true
    }
})
