$(document).ready(function() {

    $("#contact-us-form").submit(handleSubmitClick);


    function handleSubmitClick(sender) {
        sender.preventDefault();
        var isValid = validateFields();
        if (!isValid) { return }
        handleSubscription();
    }


    function handleSubscription() {
        var message = "Please Wait";
        showOvelayLoader(message);

        var firstNameField = $("input[type=text][name=fullName]");
        var emailField = $("input[type=email][name=email]");
        var messageTextArea = $("textarea[name=message]");

        $.ajax({
            url: "https://api.restorationofhearts.org/v1/contact-us",
            type: "POST",
            contentType: 'application/json',
            data: JSON.stringify({ "fullName" : firstNameField.val(), "email" : emailField.val(), "message" : messageTextArea.val(), }),
            success: function(data, textStatus, jqXHR) {
                $("input[type=text][name=fullName]").val('');
                $("input[type=email][name=email]").val('');
                $("textarea[name=message]").val('');
                var successMessage = "Thank you. We will get back to you soon!"
                updateOverlayMessage(loaderStateSuccess, successMessage);
                setTimeout(function() { closeOverlayLoader(); }, 3000);
            },
            error: function(jqXHR, textStatus, errorThrown) {

                var message = jqXHR.responseJSON["message"]
                if (message) {
                    updateOverlayMessage(loaderStateFailure, message);
                    setTimeout(function() { closeOverlayLoader(); }, 3000);
                } else {
                    var errorMessage = "Your request cannot be processed at this time, please try again later"
                    updateOverlayMessage(loaderStateFailure, errorMessage);
                    setTimeout(function() { closeOverlayLoader(); }, 3000);
                }
            }
        });
    }


    function validateFields() {

        var firstNameField = $("input[type=text][name=fullName]");
        if (validate({ value: firstNameField.val() }, isNonEmptyRule()) !== undefined) {
            firstNameField.css('border','1px solid red');
            setTimeout(function() { firstNameField.css('border',''); }, 2000);
            return false
        }

        var emailField = $("input[type=email][name=email]");
        if (validate({ email: emailField.val() }, emailRule()) !== undefined) {
            emailField.css('border','1px solid red');
            setTimeout(function() { emailField.css('border',''); }, 2000);
            return false
        }

        var messageTextArea = $("textarea[name=message]");
        if (validate({ value: messageTextArea.val() }, isNonEmptyRule()) !== undefined) {
            messageTextArea.css('border','1px solid red');
            setTimeout(function() { messageTextArea.css('border',''); }, 2000);
            return false
        }

        return true
    }
})
