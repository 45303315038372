
/* First name rule */
function firstNameRule() {
    var rules = { firstName: { presence: true, length: { minimum: 2 } } };
    return rules
}

/* Last name rule */
function lastNameRule() {
    var rules = { lastName: { presence: true, length: { minimum: 2 } } };
    return rules
}

/* Full name rule */
function fullNameRule() {
    var rules = { fullName: { presence: true, length: { minimum: 2 }, format: { pattern:/(.+)\s(.+)/ } } };
    return rules
}

/* Email rule */
function emailRule() {
    var rules = { email: { presence: true, email: true } };
    return rules
}

/* Street rule */
function addressStreetRule() {
    var rules = { street: { presence: true, format: { pattern: /\w+(\s\w+){2,}/ } } };
    return rules
}

/* City rule */
function addressCityRule() {
    var rules = { city: { presence: true, format: { pattern: /^[a-zA-Z\s-]+$/ } } };
    return rules
}

/* State rule */
function addressStateRule() {
    var rules = { state: { format: { pattern: /^[a-zA-Z\s-]+$/ } } };
    return rules
}

/* Zipcode rule */
function addressZipRule() {
    var rules = { postalCode: { format: { pattern: /\d{5}(-\d{4})?/ } } };
    return rules
}

/* Amount rule */
function isNumberRule() {
    var rules = { amount: { numericality: { strict: true, greaterThan: 0 } } };
    return rules
}

/* Non empty rule */
function isNonEmptyRule() {
    var rules = { value: { presence: true } };
    return rules
}
