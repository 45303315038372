
function showOvelayLoader(message) {
	var overlayLoader = $('#overlay-loader');
	overlayLoader.find('.loader').removeClass('d-none');
	overlayLoader.find('.success-block').addClass('d-none');
	overlayLoader.find('.failure-block').addClass('d-none');

	var messageBlock = overlayLoader.find('.message');
	overlayLoader.addClass('open');
	messageBlock.fadeOut(300, function() { 
		messageBlock.text(message);
		messageBlock.fadeIn(500);
	});
}


function updateOverlayMessage(status, message) {
	
	var overlayLoader = $('#overlay-loader');
	if (status === loaderStateLoading) {
		overlayLoader.find('.loader').removeClass('d-none');
		overlayLoader.find('.success-block').addClass('d-none');
		overlayLoader.find('.failure-block').addClass('d-none');
	} else if (status === loaderStateSuccess) {
		overlayLoader.find('.loader').addClass('d-none');
		overlayLoader.find('.success-block').removeClass('d-none');
	} else if (status === loaderStateFailure) {
		overlayLoader.find('.loader').addClass('d-none');
		overlayLoader.find('.failure-block').removeClass('d-none');
	}

	var messageBlock = overlayLoader.find('.message');
	messageBlock.fadeOut(300, function() { 
		messageBlock.text(message);
		messageBlock.fadeIn(500);
	});
}

function closeOverlayLoader() {
	var overlayLoader = $('#overlay-loader');
	var messageBlock = overlayLoader.find('.message');
	overlayLoader.removeClass('open');
	messageBlock.fadeOut(300, function() { 
		messageBlock.text('');
	});
}