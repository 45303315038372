$(document).ready(function() {
    var url = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=3&playlistId=PLc9TB876lstLhcL5uwiSRI1uv444uuIzY&key=AIzaSyAMiUc5_GyRZ_lnqe8WhwURVSpzn06ApDY"
    $.getJSON(url, function(data) {

    	var htmlString = ""
		$.each(data.items, function( index, item ) {
			
			var dataItem = { };
			
			if (typeof(item["snippet"].title) !== 'undefined') {
				dataItem.title = item["snippet"].title;
			}

			if (typeof(item["snippet"].description) !== 'undefined') {
				dataItem.description = item["snippet"].description;
			}

			if (typeof(item["snippet"].resourceId) !== 'undefined') {
				dataItem.videoId = item["snippet"].resourceId.videoId;
			}

			if (typeof(item["snippet"].publishedAt) !== 'undefined') {
				dataItem.publishedAt = item["snippet"].publishedAt;
			}

			if (typeof(item["snippet"].thumbnails) !== 'undefined') {
				if (typeof(item["snippet"].thumbnails.maxres) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.maxres.url;
				} else if (typeof(item["snippet"].thumbnails.standard) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.standard.url;
				} else if (typeof(item["snippet"].thumbnails.high) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.high.url;
				} else if (typeof(item["snippet"].thumbnails.medium) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.medium.url;
				} else if (typeof(item["snippet"].thumbnails.default) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.default.url;
				}
			}
			
			var html = "<div class=\"col-md-4 col-sm-12 mb-15\"><div class=\"latest-video\"><a href=\"//www.youtube.com/watch?v=" + dataItem.videoId + "\" data-lity><img class=\"img-fluid rounded\" src=\"" + dataItem.thumbnail + "\" alt=\"" + dataItem.title + "\" /></a></div><p class=\"mt-15\">" + dataItem.title + "</p></div>"
			htmlString += html
		});

		$( "#latest-videos" ).replaceWith(htmlString);
    });
})