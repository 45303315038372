$(document).ready(function() {
    var url = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=25&playlistId=PLc9TB876lstLhcL5uwiSRI1uv444uuIzY&key=AIzaSyAMiUc5_GyRZ_lnqe8WhwURVSpzn06ApDY"
    $.getJSON(url, function(data) {

    	var htmlString = ""
		$.each(data.items, function( index, item ) {
			
			var dataItem = { };
			
			if (typeof(item["snippet"].title) !== 'undefined') {
				dataItem.title = item["snippet"].title;
			}

			if (typeof(item["snippet"].description) !== 'undefined') {
				dataItem.description = item["snippet"].description;
			}

			if (typeof(item["snippet"].resourceId) !== 'undefined') {
				dataItem.videoId = item["snippet"].resourceId.videoId;
			}

			if (typeof(item["snippet"].publishedAt) !== 'undefined') {
				dataItem.publishedAt = item["snippet"].publishedAt;
			}

			if (typeof(item["snippet"].thumbnails) !== 'undefined') {
				if (typeof(item["snippet"].thumbnails.maxres) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.maxres.url;
				} else if (typeof(item["snippet"].thumbnails.standard) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.standard.url;
				} else if (typeof(item["snippet"].thumbnails.high) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.high.url;
				} else if (typeof(item["snippet"].thumbnails.medium) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.medium.url;
				} else if (typeof(item["snippet"].thumbnails.default) !== 'undefined') {
					dataItem.thumbnail = item["snippet"].thumbnails.default.url;
				}
			}
			
			var html = "<div class=\"row mb-80\"><div class=\"col-md-4 col-sm-12\"><a href=\"//www.youtube.com/watch?v=" + dataItem.videoId + "\" data-lity><img class=\"img-fluid rounded mb-15\" src=\"" + dataItem.thumbnail + "\" alt=\"" + dataItem.title + "\"></a></div><div class=\"col-md-8 col-sm-12\"><a href=\"//www.youtube.com/watch?v=" + dataItem.videoId + "\" data-lity><h5>" + dataItem.title + "</h5></a><p>" + dataItem.description + "</p></div></div>"
			htmlString += html
		});

		$( "#roh-videos" ).replaceWith(htmlString);
    });
})